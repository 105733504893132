import React from 'react';
import { Dropdown, Menu, Button, Tooltip } from 'antd';
import { CSVLink } from 'react-csv';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';


const ExportDropdown = ({ filename, exportColumns, data }) => {
    const currentDate = getCurrentDate();


    const getFormattedData = (data, exportType) => {
        return data?.map(item => {
            const formattedItem = { ...item };

            exportColumns.forEach(col => {
                if (/(total|amount|balance|gross|price|cost|extended)/i.test(col.key)) {
                    if (exportType === "pdf" || exportType === "word") {
                        // Keep formatted currency for PDF/Word
                        formattedItem[col.key] = `$${Number(item[col.key]).toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })}`;
                    }  else if (exportType === "csv") {
                        // Ensure CSV values are correctly formatted for Excel while keeping numeric values
                        formattedItem[col.key] = `\u200E$${Number(item[col.key]).toFixed(2)}`;
                    }else {
                        formattedItem[col.key] = Number(item[col.key]);
                    }
                }
            });

            return formattedItem;
        });
    };


    const handleExcelExport = () => {
        const headers = exportColumns.map(col => col.label);
        const excelData = getFormattedData(data, "excel").map(item => exportColumns.map(col => item[col.key]));
    
        const worksheet = XLSX.utils.aoa_to_sheet([headers, ...excelData]);
        
        // Apply currency format to amount-related columns
        exportColumns.forEach((col, colIndex) => {
            if (/(total|amount|balance|gross|price|cost|extended)/i.test(col.key)) {
                for (let rowIndex = 1; rowIndex <= excelData.length; rowIndex++) {
                    const cellAddress = XLSX.utils.encode_cell({ r: rowIndex, c: colIndex });
                    if (!worksheet[cellAddress]) continue;
                    worksheet[cellAddress].z = '"$"#,##0.00'; // Apply currency format
                }
            }
        });
    
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, `${filename}-${currentDate}.xlsx`);
    };


    const handlePdfExport = () => {
        const doc = new jsPDF();
        doc.autoTable({
            head: [exportColumns.map(col => col.label)],
            body: getFormattedData(data, "pdf").map(item => exportColumns.map(col => item[col.key])),
        });
        doc.save(`${filename}-${currentDate}.pdf`);
    };



    const exportMenu = (
        <Menu className="dropdown">
            <Menu.Item key="csv">
                <CSVLink
                    filename={`${filename}-${currentDate}.csv`}
                    data={getFormattedData(data, "csv")}
                    headers={exportColumns}
                    className="dropdown-item"
                >
                    Export CSV
                </CSVLink>
            </Menu.Item>
            <Menu.Item key="excel">
                <button onClick={handleExcelExport} className="dropdown-item">Export Excel</button>
            </Menu.Item>
            <Menu.Item key="pdf">
                <button onClick={handlePdfExport} className="dropdown-item">Export PDF</button>
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={exportMenu} placement="bottomLeft">
            <Tooltip title="Export">
                <button type="submit" className="btn btn-primary w-20 mt-2 mt-xl-0" >
                    <i className="fa-solid fa-download pointer"></i>
                </button>
            </Tooltip>
        </Dropdown>
    );
};

function getCurrentDate() {
    const currentDate = new Date();
    return currentDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
}

export default ExportDropdown;
