import React from 'react';
import {Modal} from 'antd';

const CustomModal = ({ visible, message, onCancel, onConfirm, title, okText, cancelText }) => {
  return (
    <Modal
      title={title}
      className="custom-ant-modal"
      open={visible}
      maskClosable={false}
      centered
      onCancel={() => onCancel(false)}
      footer={[
        <button
          className="btn btn-outline-secondary me-3"
          type="button"
          key="back"
          onClick={() => onCancel(false)}
        >
          {cancelText || "CANCEL"}
        </button>,
        <button
          className="btn btn-primary"
          key="submit"
          type="button"
          onClick={() => onConfirm(true)}
        >
         {okText || "CONFIRM"}
        </button>,
      ]}
    >
      <div className="row">
        <h5 className="text mb-2 text-center">
          {message}</h5>
      </div>
    </Modal>
  );
};

export default CustomModal;
