export default function Fallback({ error, resetErrorBoundary }) {
    return (
        <div className="vh-100 d-flex justify-content-center align-items-center">
            <div className="card-body">
                <h3 className="card-heading text-center">Something Went Wrong!</h3>
                <h5 className="card-title text-center" style={{ float: "none" }}>We're sorry, but an error occurred while processing your request. Please try again later.</h5>
                <div className="text-center mt-4">
                    <button type="submit" className="btn btn-primary" onClick={() => { window.location.href = "/dashboard" }}
                    >Go to Dashboard</button></div>
            </div>
        </div>
    );
}