import axios from "axios";
import LoginService from "../API/LoginService";

const api = axios.create({
    // baseURL: 'https://localhost:44384/api/',
    baseURL: "https://yiannis-api.ginilytics.com/api/",
    // baseURL: "https://yiannis-stg-api.ginilytics.com:2000/api/",
    headers: {
        'Content-Type': 'application/json',
    }
});

// Function to refresh the access token
const RefreshToken = async () => {
    const token = {
        refreshToken: localStorage.getItem('refreshToken'),
    };

    try {
        const response = await LoginService.RefreshToken(token);
        if (response.status) {
            localStorage.setItem("accessToken", response.resultData.token);
            localStorage.setItem("refreshToken", response.resultData.refreshToken);
            return response.resultData.token;
        } else {
            throw new Error('Refresh token failed');
        }
    } catch (error) {
        localStorage.clear();
        window.location.href = '/';
        throw error; // Propagate the error
    }
};

// Flag to track if a token refresh is in progress
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });
    failedQueue = [];
};

api.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;

        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise((resolve, reject) => {
                    failedQueue.push({ resolve, reject });
                })
                .then(token => {
                    originalRequest.headers.Authorization = `Bearer ${token}`;
                    return axios(originalRequest);
                })
                .catch(err => {
                    return Promise.reject(err);
                });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            try {
                const newToken = await RefreshToken();
                processQueue(null, newToken);
                originalRequest.headers.Authorization = `Bearer ${newToken}`;
                return axios(originalRequest);
            } catch (err) {
                processQueue(err, null);
                return Promise.reject(err);
            } finally {
                isRefreshing = false;
            }
        }

        return Promise.reject(error);
    }
);

export default api;
